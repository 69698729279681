import classNames from 'classnames'
import PropTypes from 'prop-types'

const CheckBoxField = props => {
  const {
    id,
    'data-testid': dataTestId,
    fieldRef,
    label,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className,
    disabled,
    readOnly,
    checked,
    indeterminate,
    onChange,
    isInvalid
  } = props

  return (
    <div
      className={classNames('checkbox-field', className, readOnly && 'readonly')}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <label
        htmlFor={id}
        className={classNames('input-checkbox', { disabled, invalid: isInvalid })}
      >
        <input
          ref={fieldRef}
          type="checkbox"
          id={id}
          data-testid={dataTestId}
          readOnly={readOnly}
          disabled={disabled}
          checked={checked}
          data-indeterminate={indeterminate ? 'true' : 'false'}
          onChange={onChange}
        />
        <span className="checkbox" />
      </label>
      <label htmlFor={id} className="checkbox-field-label">
        {label}
      </label>
    </div>
  )
}

CheckBoxField.defaultProps = {
  fieldRef: null,
  readOnly: false,
  indeterminate: false,
  className: '',
  disabled: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  onChange: null
}

CheckBoxField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fieldRef: PropTypes.func
}

export default CheckBoxField
