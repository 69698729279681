import { Component } from 'react'
import Creatable from 'react-select/creatable'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { components as reactSelectComponents } from 'react-select';
import '../../scss/common/multi-select.scss'

// Use custom input component to handle onPaste
const CustomInput = props => {
  const { innerRef, selectProps } = props

  return (
    <reactSelectComponents.Input
      {...props}
      ref={innerRef}
      onPaste={selectProps.onPaste}
    />
  )
}

const components = {
  DropdownIndicator: null
}

const createOption = label => ({
  label,
  value: label
})

const addUniqueValue = (values, value) => {
  const isUnique = !values.find(item => item.value === value)

  return isUnique ? [...values, createOption(value)] : values
}

class MultiCreatable extends Component {
  static getDerivedStateFromProps(props, state) {
    let newState = null
    const { values } = props
    const { initialValues } = state

    if (values && values !== initialValues) {
      newState = { values, initialValues: values }
    }

    return newState
  }

  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
      values: this.props.values || [],
      // don't change it via setState, it's used for object prev/next props comparison
      initialValues: this.props.values
    }

    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleBlur(event) {
    const { values } = this.state
    const value = event.target.value.trim()

    const isValid = this.isOptionValid(value)

    const newValues = isValid ? addUniqueValue(values, value) : values

    if (isValid) {
      this.handleChange(newValues)
    }

    if (this.props.onBlur) {
      this.props.onBlur(event, newValues)
    }
  }

  handleChange(values) {
    // react-select v3 begin to set values to null
    // in case user clears all values in multeselect field
    const newValues = values || []
    this.setState({ values: newValues })

    if (this.props.onChange) {
      this.props.onChange(newValues)
    }
  }

  handleInputChange(inputValue, action) {
    const { clearInputOnBlur } = this.props
    const actions = ['input-blur', 'menu-close']

    if (clearInputOnBlur) {
      this.setState({ inputValue })
    } else if (!actions.includes(action.action)) {
      this.setState({ inputValue })
    }
  }

  handleKeyDown(event) {
    const { inputValue, values } = this.state
    const { submitByKeys } = this.props

    if (!inputValue) {
      return
    }

    if (submitByKeys.includes(event.keyCode)) {
      event.preventDefault()
      event.stopPropagation()

      if (!this.isOptionValid(inputValue)) {
        return
      }

      this.setState({ inputValue: '' })

      this.handleChange(addUniqueValue(values, inputValue))
    }
  }

  isOptionValid(value) {
    const { isValidNewOption } = this.props
    const hasValidation = !!isValidNewOption

    if (!value) {
      return false
    }

    if (!hasValidation) {
      return true
    }

    return !!value && isValidNewOption(value)
  }

  render() {
    const { inputValue, values } = this.state
    const {
      isDisabled,
      createOnBlur,
      className,
      size,
      placeholder,
      onBlur,
      onFocus,
      styles,
      isLoading,
      options,
      menuOption,
      menuIsOpen,
      onPaste
    } = this.props

    const customComponents = { ...components }
    if (menuOption) {
      customComponents.Option = menuOption
    }
    if (onPaste) {
      customComponents.Input = CustomInput
    }

    return (
      <Creatable
        className={classNames('upboard-react-select multi-creatable', size, className)}
        classNamePrefix="upboard-rs"
        inputValue={inputValue}
        isLoading={isLoading}
        value={values}
        options={options}
        menuIsOpen={menuIsOpen()}
        openMenuOnClick={false}
        placeholder={placeholder}
        components={customComponents}
        isDisabled={isDisabled}
        styles={styles}
        isMulti
        onPaste={onPaste}
        onBlurResetsInput={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        onFocus={onFocus}
        onBlur={createOnBlur ? this.handleBlur : onBlur}
      />
    )
  }
}

MultiCreatable.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isValidNewOption: PropTypes.func,
  clearInputOnBlur: PropTypes.bool,
  createOnBlur: PropTypes.bool,
  submitByKeys: PropTypes.arrayOf(PropTypes.number)
}

MultiCreatable.defaultProps = {
  values: null,
  options: null,
  className: '',
  size: 'medium',
  placeholder: '',
  onChange: null,
  menuIsOpen: () => false,
  onFocus: null,
  onBlur: null,
  isValidNewOption: null,
  clearInputOnBlur: true,
  createOnBlur: true,
  submitByKeys: [9, 13] // Tab, enter
}

export default MultiCreatable
