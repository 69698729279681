/* eslint-disable import/no-cycle */
import {
  processCreateWorkflow,
  processUpdateWorkflow,
  processUpdateFlow,
  getResourcesData,
  processGetWorkflow,
  processGetFlow
} from '../api/workflowAPI'
import {
  SET_WORKFLOW_DATA,
  CLEAR_WORKFLOW_DATA,
  TOGGLE_ALL_CHANGES_SAVED,
  TOGGLE_WORKFLOW_MANDATORY_ERROR,
  TOGGLE_BLOCK_ERROR,
  CHANGE_WORKFLOW_BLOCK_INPUT,
  CHANGE_WORKFLOW_BLOCK_OUTPUT_TYPE,
  CLEAR_WORKFLOW_BLOCKS_STOPPED_INPUTS,
  CREATE_WORKFLOW_BLOCK,
  DELETE_WORKFLOW_BLOCK,
  UPDATE_WORKFLOW_BLOCK,
  CHANGE_WORKFLOW_BLOCK_OUTPUT,
  TOGGLE_VALIDATION_ERROR,
  UPDATE_WORKFLOW_DEFINITION,
  VALIDATE_ALL_BLOCK,
  VALIDATE_BLOCK,
  UPDATE_OUTPUT_NAME,
  SET_INVALID_BLOCKS,
  TOGGLE_WORKFLOW_CREATION_MODE,
  TOGGLE_WORKFLOW_LOADER,
  TOGGLE_INVALID_BLOCKS_OPEN_STATE,
  TOGGLE_WORKFLOW_OPEN_STATE,
  SET_ACTIVE_WORKFLOW,
  TOGGLE_WORKFLOW_CONFIRMATION_SHOWN
} from '../constants/actionTypes'
import messages from '../constants/messages'
import { EFieldKeys } from '../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES, BACKGROUND_TRIGGER_EVENT_TYPES } from '../constants/workflows'
import { getEntityFromResponse } from '../graphql/entities'
import { generateGUID } from '../helpers/common/commonHelpers'
import { DEFAULT_FINISH_BLOCK_DATA } from '../helpers/workflowFinishBlock/workflowFinishBlockHelpers'
import { navigateToBoardAdministration, navigateToWorkflowBuilder } from '../helpers/routesHelpers'
import {
  checkStaticErrors,
  getInvalidBlocks,
  ValidationException
} from '../helpers/workflowBuilder/blocksValidation'
import { getDefaultBlockInput } from '../helpers/workflowBuilder/inputOperations'
import { getResourcePayload, isGetBlock } from '../helpers/workflowBuilder/outputComponentHelpers'
import { createInitialBlocks } from '../helpers/workflowBuilder/workflowOperations'
import FlowToStateMapper from '../helpers/workflowBuilderMappers/FlowToStateMapper'
import StateToFlowMapper from '../helpers/workflowBuilderMappers/StateToFlowMapper'
import { showToastMessage } from './boardActions'

export function setWorkflowData(payload) {
  return { type: SET_WORKFLOW_DATA, payload }
}

export function clearWorkflowData(payload) {
  return { type: CLEAR_WORKFLOW_DATA, payload }
}

export function createWorkflow(payload) {
  return dispatch => {
    const { eventType, tenantId, boardId } = payload

    const state = createInitialBlocks()

    const triggerId = state.workflowBlocks[0]
    const triggerBlock = state.workflowBlocksMap[triggerId]

    if (triggerBlock && eventType) {
      triggerBlock.meta.eventType = eventType
    }

    const workflow = {
      ...state,
      workflowDefinition: {
        id: generateGUID(),
        flowId: generateGUID(),
        triggerId,
        tenantId,
        boardId,
        name: 'Workflow name',
        isExecutionShown: false,
        eventType
      }
    }

    dispatch(setWorkflowData(workflow))
  }
}

export function toggleAllChangesSaved(payload) {
  return { type: TOGGLE_ALL_CHANGES_SAVED, payload }
}

export function toggleWorkflowMandatoryError(payload) {
  return { type: TOGGLE_WORKFLOW_MANDATORY_ERROR, payload }
}

export function toggleBlockError(payload) {
  return { type: TOGGLE_BLOCK_ERROR, payload }
}

// to reset flags
export function updateBlockProps(payload) {
  return (dispatch, getState) => {
    const { isAllChangesSaved, isWorkflowMandatoryError, invalidBlocksMap } = getState().workflow
    if (isAllChangesSaved) {
      dispatch(toggleAllChangesSaved(false))
    }
    if (isWorkflowMandatoryError) {
      dispatch(toggleWorkflowMandatoryError(false))
    }
    if (payload.blockId && invalidBlocksMap[payload.blockId]) {
      // if value was changed, reset error
      dispatch(toggleBlockError({ id: payload.blockId }))
    }
  }
}

export function changeWorkflowBlockInput(payload) {
  return dispatch => {
    dispatch(updateBlockProps(payload))
    dispatch({ type: CHANGE_WORKFLOW_BLOCK_INPUT, payload })
  }
}

export const changeWorkflowBlockOutputType = payload => dispatch => {
  dispatch(updateBlockProps(payload))
  dispatch({ type: CHANGE_WORKFLOW_BLOCK_OUTPUT_TYPE, payload })
}

export function clearWorkflowBlocksStoppedInput(payload) {
  return dispatch => {
    dispatch(updateBlockProps(payload))
    dispatch({ type: CLEAR_WORKFLOW_BLOCKS_STOPPED_INPUTS })
  }
}

export function createWorkflowBlock(payload) {
  return (dispatch, getState) => {
    dispatch(updateBlockProps({}))
    dispatch({ type: CREATE_WORKFLOW_BLOCK, payload })
    const state = getState().workflow
    const blockId = state.workflowBlocks[payload.index]
    const input = getDefaultBlockInput({ ...state, blockId })
    Object.entries(input).forEach(([fieldName, outputId]) => {
      dispatch(changeWorkflowBlockInput({ fieldName, outputId, blockId }))
    })
  }
}

export function deleteWorkflowBlock(payload) {
  return dispatch => {
    dispatch(updateBlockProps({ blockId: payload }))
    dispatch({ type: DELETE_WORKFLOW_BLOCK, payload })
  }
}

export function updateWorkflowBlock(payload) {
  return dispatch => {
    dispatch(updateBlockProps({ blockId: payload.id }))
    dispatch({ type: UPDATE_WORKFLOW_BLOCK, payload })
  }
}

export function changeWorkflowBlockOutput(payload) {
  return dispatch => {
    dispatch(updateBlockProps({ blockId: payload.id }))
    dispatch({ type: CHANGE_WORKFLOW_BLOCK_OUTPUT, payload })
  }
}

export function updateWorkflowTriggerBlock(payload) {
  return (dispatch, getState) => {
    const { workflowBlocks } = getState().workflow
    const { meta, output } = payload.data

    if (output) {
      const updatedOutputs = output.map(item =>
        item.id ? item : { ...item, id: generateGUID(), blockId: payload.id }
      )

      dispatch(
        changeWorkflowBlockOutput({
          id: payload.id,
          data: { ...payload.data, output: updatedOutputs }
        })
      )
    } else {
      dispatch(updateWorkflowBlock(payload))
    }

    // Reset the finish block, if it's a background event.
    const shouldResetFinishBlock = BACKGROUND_TRIGGER_EVENT_TYPES.includes(meta?.eventType)

    if (shouldResetFinishBlock) {
      const finishBlockId = workflowBlocks[workflowBlocks.length - 1]

      dispatch(updateWorkflowBlock({ id: finishBlockId, data: DEFAULT_FINISH_BLOCK_DATA }))
    }
  }
}

export function toggleValidationError(payload) {
  return { type: TOGGLE_VALIDATION_ERROR, payload }
}

export function updateWorkflowDefinition(payload) {
  return dispatch => {
    dispatch(updateBlockProps({}))
    dispatch({ type: UPDATE_WORKFLOW_DEFINITION, payload })
  }
}

export function validateAllBlock() {
  return { type: VALIDATE_ALL_BLOCK }
}

export function validateBlock(payload) {
  return { type: VALIDATE_BLOCK, payload }
}

export function validateActiveBlock() {
  return (dispatch, getState) => {
    const { activeBlockId, workflowBlocksMap } = getState().workflow
    if (activeBlockId !== -1 && workflowBlocksMap[activeBlockId]) {
      dispatch(validateBlock(activeBlockId))
    }
  }
}

export function updateOutputName(payload) {
  return { type: UPDATE_OUTPUT_NAME, payload }
}

export function setInvalidBlocks(payload) {
  return { type: SET_INVALID_BLOCKS, payload }
}

export function toggleWorkflowCreationMode(payload) {
  return { type: TOGGLE_WORKFLOW_CREATION_MODE, payload }
}

export function createWorkflowData() {
  return (dispatch, getState) => {
    const { workflowDefinition, workflowBlocks, workflowBlocksMap, outputs, outputsHistory } =
      getState().workflow

    const Mapper = new StateToFlowMapper()
    const data = Mapper.convert({
      workflowDefinition,
      workflowBlocks,
      workflowBlocksMap,
      outputs,
      outputsHistory
    })

    return processCreateWorkflow({
      tenantId: workflowDefinition.tenantId,
      boardId: workflowDefinition.boardId,
      data
    }).then(response => {
      const workflow = response.data

      dispatch(toggleWorkflowCreationMode(false))
      dispatch(
        updateWorkflowDefinition({
          id: workflow.id,
          flowId: workflow.flowId
        })
      )

      navigateToWorkflowBuilder({
        tenantId: workflow.tenantId,
        boardId: workflow.boardId,
        workflowId: workflow.id
      })
    })
  }
}

export function updateWorkflowData() {
  return (dispatch, getState) => {
    const { workflowDefinition, workflowBlocks, workflowBlocksMap, outputs, outputsHistory } =
      getState().workflow

    const Mapper = new StateToFlowMapper()
    const { workflow, flow } = Mapper.convert({
      workflowDefinition,
      workflowBlocks,
      workflowBlocksMap,
      outputs,
      outputsHistory
    })

    return Promise.all([
      processUpdateWorkflow({
        tenantId: workflowDefinition.tenantId,
        boardId: workflowDefinition.boardId,
        id: workflow.id,
        data: workflow
      }),
      processUpdateFlow({
        tenantId: workflowDefinition.tenantId,
        boardId: workflowDefinition.boardId,
        id: workflow.flowId,
        data: flow
      })
    ])
  }
}

export function toggleWorkflowBuilderLoader(payload) {
  return { type: TOGGLE_WORKFLOW_LOADER, payload }
}

export function toggleInvalidBlocksOpenState() {
  return { type: TOGGLE_INVALID_BLOCKS_OPEN_STATE }
}

export function setOutputEntity({ block, outputId, entity, type, error }) {
  return dispatch => {
    const outputEntity = { outputId, error }
    switch (type) {
      case OBJECT_TYPES.BOARD:
        outputEntity.name = entity.name
        break
      case OBJECT_TYPES.COLUMN:
        outputEntity.name = entity.name
        break
      case OBJECT_TYPES.CARD:
        outputEntity.name = entity.name
        break
      case OBJECT_TYPES.WIDGET:
        outputEntity.name = entity.widgetTitle
        break
      case OBJECT_TYPES.WIDGET_DATA:
        outputEntity.name = 'Widget Data [1,1]'
        break
      case OBJECT_TYPES.USER:
        outputEntity.name = entity.username
        break
      default:
    }
    if (isGetBlock(block.type)) {
      const meta = {
        [EFieldKeys.ENTITY_INPUT]: { ...entity, error }
      }
      const payload = {
        id: block.id,
        data: {
          meta,
          error: {}
        }
      }
      dispatch({ type: UPDATE_WORKFLOW_BLOCK, payload })
    }
  }
}

export function requestEntities({ organizationId, tenantId, payload = [] }) {
  return dispatch => {
    if (!payload.length) {
      return Promise.resolve([])
    }
    return getResourcesData({ organizationId, tenantId, payload }).then(response => {
      const { data } = response
      const invalidOutputs = []
      payload.forEach(obj => {
        const { outputId, block, type, entity } = obj.entity
        const resource = getEntityFromResponse({ organizationId, data, entity })
        if (!resource.exists) {
          invalidOutputs.push(outputId)
        }
        dispatch(
          setOutputEntity({
            block,
            outputId,
            type,
            entity: resource,
            error: !resource.exists && messages.OBJECT_NOT_FOUND(type)
          })
        )
      })
      return invalidOutputs
    })
  }
}

export function getEntityResources({ tenantId }) {
  return (dispatch, getState) => {
    const { workflowBlocksMap } = getState().workflow
    const { organizationId } = getState().profile.activeOrganization
    const payload = Object.values(workflowBlocksMap).reduce((arr, block) => {
      if (isGetBlock(block.type)) {
        Object.values(block.inputDefinition).forEach(input => {
          if (input.isStatic) {
            const entity = block.meta[input.inputName]
            const { type, id } = block.output[0]
            const payloadData = getResourcePayload({ entity, type })
            const payloadEntity = { outputId: id, entity, type, block }
            arr.push({
              data: { organizationId, tenantId, ...payloadData },
              entity: payloadEntity
            })
          }
        })
      }
      return arr
    }, [])
    return dispatch(requestEntities({ organizationId, tenantId, payload })).then(invalidOutputs => {
      const invalidBlocksMap = getInvalidBlocks({ invalidOutputs, workflowBlocksMap })
      dispatch(setInvalidBlocks(invalidBlocksMap))

      return invalidBlocksMap
    })
  }
}

/**
 *
 * @param {Object}
 * tenantId - workflow tenant,
 * validationErrorState - flag to show error modal after validation
 * Requests entities from all get blocks and validates them
 */
export function validateWorkflow({ tenantId, validationErrorState }) {
  return (dispatch, getState) => {
    dispatch(validateAllBlock())
    // first of all validate all blocks and then check errors
    const { workflowBlocksMap, workflowDefinition } = getState().workflow

    const isStaticErrors = checkStaticErrors(workflowBlocksMap)
    if (!workflowDefinition.name || isStaticErrors) {
      dispatch(toggleWorkflowMandatoryError(true))
      dispatch(toggleInvalidBlocksOpenState())

      return new Promise(() => {
        throw new ValidationException({
          isStaticErrors: true
        })
      })
    }

    dispatch(toggleWorkflowBuilderLoader(true))

    return dispatch(getEntityResources({ tenantId })).then(invalidBlocksMap => {
      if (Object.keys(invalidBlocksMap).length) {
        dispatch(toggleValidationError(validationErrorState))
        dispatch(toggleWorkflowBuilderLoader(false))
        dispatch(toggleInvalidBlocksOpenState())

        throw new ValidationException({ isDynamicErrors: true })
      }
    })
  }
}

export function saveWorkflow() {
  return (dispatch, getState) => {
    const { isWorkflowCreation } = getState().workflow

    const promise = isWorkflowCreation
      ? dispatch(createWorkflowData())
      : dispatch(updateWorkflowData())

    return promise
      .then(() => dispatch(toggleAllChangesSaved(true)))
      .catch(() => {
        dispatch(
          showToastMessage({
            text: 'Something went wrong while saving the workflow. Please try again.',
            size: 'M'
          })
        )
      })
      .finally(() => dispatch(toggleWorkflowBuilderLoader(false)))
  }
}

export function toggleWorkflowOpenState(payload) {
  return { type: TOGGLE_WORKFLOW_OPEN_STATE, payload }
}

export function setActiveWorkflow(payload) {
  return { type: SET_ACTIVE_WORKFLOW, payload }
}

export function toggleWorkflowConfirmation(payload) {
  return { type: TOGGLE_WORKFLOW_CONFIRMATION_SHOWN, payload }
}

export function getWorkflowData(payload) {
  let workflow = {}

  return processGetWorkflow(payload)
    .then(response => {
      workflow = response.data

      return processGetFlow({ ...payload, flowId: workflow.flowId })
    })
    .then(flowResponse => ({
      workflow,
      flow: {
        id: flowResponse.data.id,
        connections: flowResponse.data.payload.connections,
        processes: flowResponse.data.payload.processes
      }
    }))
}

export function editWorkflow(payload) {
  return dispatch => {
    dispatch(toggleWorkflowBuilderLoader(true))

    return getWorkflowData(payload)
      .then(response => {
        const Mapper = new FlowToStateMapper()

        return dispatch(setWorkflowData(Mapper.convert(response)))
      })
      .then(() => dispatch(validateWorkflow(payload)))
      .finally(() => dispatch(toggleWorkflowBuilderLoader(false)))
      .catch(err => {
        console.error(err)

        if (err.error && (err.error.isDynamicErrors || err.error.isStaticErrors)) {
          return
        }

        navigateToBoardAdministration({
          tenantId: payload.tenantId,
          boardId: payload.boardId,
          tab: 'workflows'
        })
      })
  }
}
