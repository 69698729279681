import {
  type Placement,
  type UseFloatingReturn,
  autoUpdate,
  flip as flipMiddleware,
  hide as hideMiddleware,
  limitShift,
  offset as offsetMiddleware,
  shift as shiftMiddleware,
  useFloating
} from '@floating-ui/react'
import { useUpdateEffect } from 'react-use'
import { type TFloatingRibbonPositioningOptions } from './floatingRibbon.types'

export const useRibbonPositioning = ({
  anchor,
  boundary,
  placement,
  offset,
  shiftOffset
}: TFloatingRibbonPositioningOptions): UseFloatingReturn<HTMLElement> => {
  const floatingState = useFloating<HTMLElement>({
    open: !!anchor,
    elements: {
      reference: anchor
    },
    middleware: [
      offsetMiddleware(offset),
      flipMiddleware({
        crossAxis: false,
        boundary: boundary ?? undefined
      }),
      shiftMiddleware({
        limiter: limitShift({
          mainAxis: true,
          crossAxis: true
        }),
        crossAxis: true,
        boundary: boundary ?? undefined,
        padding: shiftOffset
      }),
      hideMiddleware()
    ],
    placement: placement as Placement,
    whileElementsMounted: autoUpdate
  })

  // Explicitly update the anchor reference when it changes.
  // This is necessary to support cases where we don't close the ribbon, but anchor does change.
  useUpdateEffect(() => {
    const { elements, refs, update } = floatingState

    if (anchor && elements.reference && elements.floating) {
      refs.setReference(anchor)
      update()
    }
  }, [anchor])

  return floatingState
}
