import {
  type ChangeEvent,
  type CSSProperties,
  type FocusEvent,
  type KeyboardEvent,
  type RefObject,
  Component,
  createRef
} from 'react'
import { Backdrop } from '@mui/material'
import { type SelectProps } from '@mui/material/Select'
import { type TooltipProps } from '@mui/material/Tooltip'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import classNames from 'classnames'
import { AutocompleteSelect } from 'components/common/select/autocompleteSelect/autocompleteSelect'
import { type TSelectSize, Select } from 'components/common/select/select'
import { TextField } from 'components/common/textField/textField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import './editable-dropdown-menu.scss'

type TOption = {
  value: string
  label: string
}

type TProps = typeof EditableDropdownMenu.defaultProps &
  Pick<SelectProps, 'MenuProps'> & {
    valuePropName: string
    styles?: CSSProperties
    size?: TSelectSize
    isDisabled?: boolean
    isEditable?: boolean
    className?: string
    placeholder?: string
    showChecked?: boolean
    onSelect: (value: string) => void
    isClearable?: boolean
    showSeparator?: boolean
    value: string
    options: TOption[]
    tooltipProps?: {
      id: string
      placement: TooltipProps['placement']
      tooltipText: string
    }
    maxLength?: number
    getOptionLabel?: (option: unknown) => string
    getOptionValue?: (option: TOption | null) => string
    validateInput: (value: string) => boolean
    hasStringValue?: boolean
  }

type TState = {
  isOpen: boolean
  inputValue: string
  selectedOption: TOption | null
}

class EditableDropdownMenu extends Component<TProps, TState> {
  inputRef: RefObject<HTMLInputElement> = createRef()

  static defaultProps = {
    getOptionLabel: (option: unknown) => (option as TOption).label || '',
    getOptionValue: (option: TOption | null) => option?.value ?? ''
  }

  constructor(props: TProps) {
    super(props)
    this.state = {
      isOpen: false,
      inputValue: '',
      selectedOption: null
    }
  }

  componentDidMount() {
    this.setNewState()
  }

  componentDidUpdate(prevProps: TProps) {
    // to prevent endless state updating while value and prevValue are NaN
    // because NaN !== NaN always results in true
    if (
      prevProps.value !== this.props.value &&
      ((typeof this.props.value === 'number' && !isNaN(this.props.value)) ||
        this.props.hasStringValue)
    ) {
      this.setNewState()
    }
  }

  setNewState = () => {
    const { value, options, valuePropName = 'value' } = this.props

    const selectedOption = findSelectedOptionByKey(value, options, valuePropName) as TOption | null

    this.setState({
      inputValue: selectedOption ? this.getInputValue(selectedOption) : value,
      selectedOption: selectedOption || { value, label: value }
    })
  }

  getInputValue = (selectedOption: TOption): string => {
    const { getOptionLabel, isEditable } = this.props

    if (!isEditable) return ''

    return getOptionLabel(selectedOption)
  }

  onSelect = (value: string) => {
    const { onSelect } = this.props
    if (onSelect) {
      onSelect(value)
    }
  }

  onBlur = (event: FocusEvent) => {
    const { value } = this.props

    const target = event.target as HTMLInputElement
    const currentInputValue = target.value.trim()
    const inputValue = currentInputValue || value

    if (inputValue !== value) {
      this.setState({ inputValue })
      this.onSelect(inputValue)
    }
  }

  onChange = ({ value }: TOption) => {
    const { getOptionValue, options, valuePropName } = this.props

    if (value !== null && value !== undefined) {
      const selectedOption = findSelectedOptionByKey(value, options, valuePropName) as TOption

      this.setState({ selectedOption, inputValue: this.getInputValue(selectedOption) })

      if (this.inputRef.current) {
        this.inputRef.current.value = getOptionValue(selectedOption)
        this.inputRef.current.blur()
      } else {
        this.onSelect(getOptionValue(selectedOption))
      }
    }
  }

  onInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputValue: string
  ) => {
    const { validateInput } = this.props

    if (!event) return
    const actions = ['blur', 'close']

    let isValid = true

    if (inputValue && validateInput) {
      isValid = validateInput(inputValue)
    }

    if (!actions.includes(event.type) && isValid) {
      this.setState({ inputValue, selectedOption: null })
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.blurSelectInput()
      event.preventDefault()
    }
  }

  blurSelectInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.blur()
    }
  }

  render() {
    const { inputValue, selectedOption, isOpen } = this.state
    const {
      size = 'medium',
      styles,
      options = [],
      className,
      placeholder,
      showChecked,
      showSeparator,
      isDisabled,
      maxLength,
      isEditable = true,
      getOptionLabel,
      getOptionValue,
      tooltipProps,
      MenuProps
    } = this.props

    return (
      <Tooltip
        id={tooltipProps?.id}
        placement={tooltipProps?.placement}
        title={!isOpen ? tooltipProps?.tooltipText : ''}
        shouldWrapChildrenWithDiv
      >
        {isEditable ? (
          <>
            <Backdrop
              className="editable-dropdown-menu-backdrop"
              open={isOpen}
              onClick={() => this.setState({ isOpen: false })}
            />
            <AutocompleteSelect
              style={styles}
              className={classNames(
                className,
                'editable-dropdown-menu',
                {
                  'show-checked': showChecked,
                  'show-separator': showSeparator,
                  open: isOpen,
                  editable: isEditable
                },
                size
              )}
              options={options}
              value={selectedOption}
              getOptionLabel={getOptionLabel}
              renderInput={params => (
                <TextField
                  value={inputValue}
                  inputRef={this.inputRef}
                  onChange={event => this.onInputChange(event, event.target.value)}
                  {...params}
                  slotProps={{ htmlInput: { ...params.inputProps, maxLength } }}
                  size={34}
                />
              )}
              popupIcon={<ArrowIcon className="editable-dropdown-menu-icon" />}
              filterOptions={filteredOptions => filteredOptions}
              // @ts-expect-error
              placeholder={placeholder}
              disabled={isDisabled}
              selectOnFocus={false}
              clearOnBlur={false}
              onOpen={() => this.setState({ isOpen: true })}
              onClose={() => this.setState({ isOpen: false })}
              onChange={(_, newValue) => this.onChange(newValue as TOption)}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
            />
          </>
        ) : (
          <Select
            className={classNames(
              className,
              'editable-dropdown-menu',
              {
                'show-checked': showChecked,
                'show-separator': showSeparator,
                open: isOpen
              },
              size
            )}
            size="custom"
            options={options.map(o => ({
              ...o,
              label: getOptionLabel(o),
              value: getOptionValue(o)
            }))}
            value={getOptionValue(selectedOption)}
            renderValue={() => getOptionLabel(selectedOption)}
            MenuProps={{ sx: { maxHeight: '350px' }, ...MenuProps }}
            IconComponent={props => (
              <div className="editable-dropdown-menu-icon">
                <ArrowIcon {...props} />
              </div>
            )}
            // @ts-expect-error
            onChange={event => this.onChange(event.target)}
          />
        )}
      </Tooltip>
    )
  }
}

export default EditableDropdownMenu
