import { type MiddlewareData, type Placement } from '@floating-ui/react'

export const getFloatingRibbonContainerAnimation = (
  placement: Placement,
  middlewareDate: MiddlewareData
) => {
  const y = placement === 'bottom' ? '-100%' : '100%'
  const shouldHide = middlewareDate.hide?.referenceHidden
  const animatedOpacity = shouldHide ? 0 : 1

  return {
    initial: { opacity: 0, y },
    animate: { opacity: animatedOpacity, y: 0 },
    exit: { opacity: 0, y }
  }
}
