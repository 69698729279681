import { type RefObject } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { Popper } from 'components/common/popper/popper'
import { Tooltip } from 'components/common/tooltip/tooltip'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { type IWidgetPickerItem } from 'components/widgetPickerDropdown/widgetPickerDropdown.types'
import { WIDGET_ICONS } from 'features/widgets/widgets.constants'
import './widget-picker-dropdown-menu.scss'

type TProps = {
  widgets: Array<{ board: { boardName: string; boardId: string }; widgets: IWidgetPickerItem[] }>
  isOpen: boolean
  dropdownMenuClassname?: string
  onSelect: (widget: IWidgetPickerItem) => void
  toggle: (state: boolean) => void
  isWidgetsListFetching: boolean
  menuAnchorRef: RefObject<Element>
}

export const WidgetPickerDropdownMenu = ({
  widgets,
  isOpen,
  dropdownMenuClassname,
  onSelect,
  toggle,
  isWidgetsListFetching,
  menuAnchorRef
}: TProps) => {
  const handlePopupClose = (e: MouseEvent | TouchEvent) => {
    if (!menuAnchorRef.current?.contains(e.target as Node)) {
      toggle(false)
    }
  }

  return (
    <Popper
      id="widget-picker-dropdown-menu"
      className={classNames('widget-picker-dropdown-menu', dropdownMenuClassname)}
      open={isOpen}
      container={menuAnchorRef.current}
      anchorEl={menuAnchorRef.current}
      placement="bottom"
      onClose={handlePopupClose}
    >
      <div className="widget-picker-dropdown-menu-content">
        {isWidgetsListFetching && <DotsSpinner color="dark" />}
        {!widgets.length && !isWidgetsListFetching && (
          <div className="empty-state">{messages.NO_WIDGETS_FOUND}</div>
        )}
        {!!widgets.length &&
          !isWidgetsListFetching &&
          widgets.map(item => (
            <div key={item.board.boardId} className="board-group">
              {item.board.boardName && (
                <div className="board-title bold">{item.board.boardName}</div>
              )}
              <ul className="widgets-group">
                {item.widgets.map(widget => (
                  <Tooltip
                    key={`${widget.cardUuid}-${widget.widgetUuid}`}
                    title={
                      <div>
                        <div>{widget.widgetTitle}</div>
                        <div className="tooltip-card-name">{widget.cardName}</div>
                      </div>
                    }
                    className="widget-picker-dropdown-tooltip"
                    placement="bottom"
                  >
                    <div className="widget-item" onClick={() => onSelect(widget)}>
                      <div className="widget-name bold">
                        <div className="icon">{WIDGET_ICONS[widget.widgetClassName]}</div>
                        {widget.widgetTitle}
                      </div>
                      <div className="card-name">{widget.cardName}</div>
                    </div>
                  </Tooltip>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </Popper>
  )
}
