// eslint-disable-next-line import/no-namespace
import * as organizationAPI from 'api/organizationAPI'

// eslint-disable-next-line import/no-namespace
import * as userAPI from 'api/userAPI'
import messages from 'constants/messages'

// eslint-disable-next-line import/no-namespace
import * as actions from 'constants/actionTypes'
import { PATHS } from 'constants/paths.constants'
import { history } from 'services/common/history.service'
import { invalidateFetchCacheItem } from 'helpers/fetchHelpers'
import { isTextErrorMessage, USER_REMOVING_ERROR_CODES } from 'helpers/errorsHandlingHelpers'
import { navigateToBoard } from 'helpers/routesHelpers'
import { closeSocketConnection } from 'helpers/socketHelpers'
import { useTeamStore } from 'features/team/team.store'

// eslint-disable-next-line import/no-namespace
import * as tenantAPI from '../api/tenantAPI'
import { showToastMessage } from './boardActions'
import { boardMenuRequest } from './profileActions'
import { clearSocketConnection } from './socketActions'
import { hideModalWindowSpinner, showModalWindowSpinner } from './spinnerActions'

// Removed cached user list and re-fetch it for the store.
export const invalidateUserList = tenantId => {
  invalidateFetchCacheItem(`tenant/${tenantId}/users/list?avatars=true`)

  void useTeamStore.getState().fetchMembers()
}

// toggle team member deactivation modal window
export function toggleDeactivateTenantMemberModal(payload) {
  return { type: actions.TOGGLE_DEACTIVATING_TENANT_MEMBER_MODAL, payload }
}

export function deactivateMembersReceive(payload) {
  return { type: actions.RECEIVE_DEACTIVATE_MEMBERS, payload }
}

export function deactivateMembersRequest(payload) {
  return dispatch => {
    dispatch(showModalWindowSpinner())
    return userAPI
      .deactivateUser(payload)
      .then(() => {
        dispatch(deactivateMembersReceive(payload))
      })
      .then(() => invalidateUserList(payload.tenantId))
      .finally(() => {
        dispatch(hideModalWindowSpinner())
        dispatch(toggleDeactivateTenantMemberModal({ state: false }))
      })
      .catch(err => {
        if (err.errorCode === 409 && isTextErrorMessage(err.message)) {
          dispatch(showToastMessage({ text: err.message }))
        }

        console.error(err)
      })
  }
}

export function toggleInvitationModal(payload) {
  return { type: actions.TOGGLE_INVITATION_MODAL, payload }
}

export function toggleInviteMembersLoader(payload) {
  return { type: actions.TOGGLE_INVITE_MEMBERS_LOADER, payload }
}

export function inviteMembers(payload, isReinvite = false) {
  return dispatch => {
    dispatch(toggleInviteMembersLoader(true))
    return userAPI
      .inviteUser(payload)
      .then(() => {
        dispatch(toggleInviteMembersLoader(false))

        const text = isReinvite
          ? messages.INVITE_SENT
          : `${messages.EXISTING_USERS_ARE_ADDED} ${payload.teamName} ${messages.TEAM}.`

        dispatch(
          showToastMessage({
            text,
            size: 'M'
          })
        )
      })
      .then(() => invalidateUserList(payload.tenantId))
      .catch(() => {
        dispatch(toggleInviteMembersLoader(false))

        return Promise.reject()
      })
  }
}

export function editMembers(payload) {
  return dispatch => {
    dispatch(toggleInviteMembersLoader(true))
    return userAPI
      .editUserRoles(payload)
      .then(() => {
        dispatch(toggleInviteMembersLoader(false))

        const text = `${payload.username}${messages.PERMISSIONS_ARE_UPDATED} ${payload.teamName} ${messages.TEAM}.`
        dispatch(
          showToastMessage({
            text,
            size: 'M'
          })
        )
      })
      .then(() => invalidateUserList(payload.tenantId))
      .catch(() => dispatch(toggleInviteMembersLoader(false)))
  }
}

export function changePasswordRequestStart() {
  return { type: actions.CHANGE_PASSWORD_REQUEST }
}

export function changePasswordReceive(payload) {
  return { type: actions.CHANGE_PASSWORD_RECEIVE, payload }
}

export function changePasswordReceiveError(payload) {
  return { type: actions.CHANGE_PASSWORD_RECEIVE_ERROR, payload }
}

export function changePassword(payload) {
  return dispatch => {
    dispatch(changePasswordRequestStart())
    return userAPI
      .changeUserPassword(payload)
      .then(response => {
        dispatch(changePasswordReceive(response))
        const text = messages.PASSWORD_CHANGED_SUCCESS
        dispatch(showToastMessage({ text }))

        return response
      })
      .catch(err => dispatch(changePasswordReceiveError(err)))
  }
}

export function toggleTerminateUserModal(payload) {
  return { type: actions.TOGGLE_TERMINATE_USER_MODAL, payload }
}

export function terminateUserRequestStart() {
  return { type: actions.TERMINATE_USER_REQUEST }
}

export function terminateUserReceive(payload) {
  return { type: actions.TERMINATE_USER_RECEIVE, payload }
}

export function terminateUserReceiveError(payload) {
  return { type: actions.TERMINATE_USER_RECEIVE_ERROR, payload }
}

export function terminateUser(payload) {
  return dispatch => {
    dispatch(terminateUserRequestStart())
    dispatch(showModalWindowSpinner())
    dispatch(clearSocketConnection())
    return userAPI
      .terminateUser(payload)
      .then(() => {
        dispatch(terminateUserReceive())
        dispatch(hideModalWindowSpinner())
        dispatch(toggleTerminateUserModal(false))
        history.push(PATHS.signUp.url)
        closeSocketConnection()
      })
      .catch(err => {
        dispatch(terminateUserReceiveError(err))
        dispatch(hideModalWindowSpinner())
        if (err.errorCode === 409) {
          dispatch(toggleTerminateUserModal(false))

          const tenants = err.tenants ? err.tenants.map(tenant => tenant.name) : []

          const codes = {
            [USER_REMOVING_ERROR_CODES.USER_ONLY_ADMIN]: messages.ONLY_ADMIN_IN_THE_TEAMS(
              tenants.join(', ')
            ),
            [USER_REMOVING_ERROR_CODES.USER_HAVE_FILTERS]:
              messages.CANT_DELETE_USER_WITH_CONNECTED_FILTERS(tenants.join(', '))
          }

          const text = codes[err.code] || ''

          if (text) {
            dispatch(
              showToastMessage({
                text,
                size: 'M'
              })
            )
          }
        }
      })
  }
}

export function setLeavingTeamData(payload) {
  return { type: actions.SET_LEAVING_TEAM_DATA, payload }
}

export function cleanUpLeavingTeamData() {
  return { type: actions.CLEAN_UP_LEAVING_TEAM_DATA }
}

export function toggleDeleteTeamMemberModal(payload) {
  return { type: actions.TOGGLE_DELETE_TEAM_MEMBER_MODAL, payload }
}

export function toggleDeleteTeamMemberRejectionModal(payload) {
  return { type: actions.TOGGLE_DELETE_TEAM_MEMBER_REJECTION_MODAL, payload }
}

export function deleteTeamMemberRequestStart() {
  return { type: actions.DELETE_TEAM_MEMBER_REQUEST }
}

export function deleteTeamMemberReceive(payload) {
  return { type: actions.DELETE_TEAM_MEMBER_RECEIVE, payload }
}

export function deleteTeamMemberReceiveError(payload) {
  return { type: actions.DELETE_TEAM_MEMBER_RECEIVE_ERROR, payload }
}

export function deleteTeamMember(payload) {
  return dispatch => {
    dispatch(deleteTeamMemberRequestStart())
    dispatch(showModalWindowSpinner())
    return tenantAPI
      .deleteTenantMember(payload)
      .then(() => {
        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'Member',
          eventAction: 'Leave Team',
          eventLabel: 'Boards Pulldown - Dots - Leave Team'
        })

        dispatch(deleteTeamMemberReceive())
        dispatch(hideModalWindowSpinner())
        dispatch(toggleDeleteTeamMemberModal(false))
        dispatch(cleanUpLeavingTeamData())

        return dispatch(boardMenuRequest(true))
      })
      .catch(err => {
        dispatch(deleteTeamMemberReceiveError(err))
        dispatch(hideModalWindowSpinner())

        if (err.errorCode !== 409) {
          return
        }

        if (err.code === USER_REMOVING_ERROR_CODES.USER_ONLY_ADMIN) {
          dispatch(toggleDeleteTeamMemberRejectionModal(true))
        } else if (isTextErrorMessage(err.message)) {
          dispatch(showToastMessage({ text: err.message }))
        }

        dispatch(toggleDeleteTeamMemberModal(false))
        dispatch(cleanUpLeavingTeamData())
      })
  }
}

export function toggleDeleteTeamModal(payload) {
  return { type: actions.TOGGLE_DELETE_TEAM_MODAL, payload }
}

export function deleteTeamRequestStart() {
  return { type: actions.DELETE_TEAM_REQUEST }
}

export function deleteTeamReceive(payload) {
  return { type: actions.DELETE_TEAM_RECEIVE, payload }
}

export function deleteTeamReceiveError(payload) {
  return { type: actions.DELETE_TEAM_RECEIVE_ERROR, payload }
}

export function deleteTeam(payload) {
  return (dispatch, getState) => {
    dispatch(deleteTeamRequestStart())
    dispatch(showModalWindowSpinner())
    return tenantAPI
      .deleteTenant(payload)
      .then(() => {
        const {
          boardMenu: { tenants }
        } = getState().profile

        // eslint-disable-next-line no-undef
        ga('send', {
          hitType: 'event',
          eventCategory: 'Team',
          eventAction: 'Delete',
          eventValue: tenants.length - 1
        })

        dispatch(deleteTeamReceive())
        dispatch(hideModalWindowSpinner())
        dispatch(toggleDeleteTeamModal(false))
        const text = messages.TEAM_HAS_BEEN_REMOVED(payload.teamName)
        dispatch(
          showToastMessage({
            text,
            size: 'M'
          })
        )
        return dispatch(boardMenuRequest(true))
      })
      .catch(err => {
        dispatch(deleteTeamReceiveError(err))
        dispatch(hideModalWindowSpinner())
      })
  }
}

export function currentTenantReceive(payload) {
  return { type: actions.RECEIVE_CURRENT_TENANT, payload }
}

export function updateCurrentTenant(payload) {
  return { type: actions.UPDATE_CURRENT_TENANT, payload }
}

export function getTenantInfo(payload) {
  return dispatch =>
    Promise.all([
      tenantAPI.getTeamSettingsRequest(payload),
      tenantAPI.getTenantPermissions(payload)
    ])
      .then(([tenant, permissions]) =>
        dispatch(
          currentTenantReceive({
            tenant: tenant.data,
            permissions: permissions.data
          })
        )
      )
      .catch(err => {
        if (err.errorCode === 404) {
          navigateToBoard({ isRecent: true })
        }

        console.error(err)
      })
}

export function toggleDeleteOrganizationModal(payload) {
  return { type: actions.TOGGLE_DELETE_ORGANIZATION_MODAL, payload }
}

export function deleteOrganizationRequestStart() {
  return { type: actions.DELETE_ORGANIZATION_REQUEST }
}

export function deleteOrganizationReceive(payload) {
  return { type: actions.DELETE_ORGANIZATION_RECEIVE, payload }
}

export function deleteOrganizationReceiveError(payload) {
  return { type: actions.DELETE_ORGANIZATION_RECEIVE_ERROR, payload }
}

export function deleteOrganization(payload) {
  return dispatch => {
    dispatch(deleteOrganizationRequestStart())
    dispatch(showModalWindowSpinner())
    return organizationAPI
      .deleteOrganization(payload)
      .then(() => {
        dispatch(deleteOrganizationReceive())
        dispatch(hideModalWindowSpinner())
        dispatch(toggleDeleteOrganizationModal(false))
        history.push(PATHS.signUp.url)
        const text = `${messages.DELETE_ORGANIZATION_CONFIRMATION(payload.data.organizationName)}`
        dispatch(
          showToastMessage({
            text,
            size: 'M'
          })
        )
      })
      .catch(err => {
        dispatch(
          deleteOrganizationReceiveError(err.errorCode === 403 ? err.message.message : err.message)
        )
        dispatch(hideModalWindowSpinner())
      })
  }
}
