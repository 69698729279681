import { ApolloClient } from 'apollo-client'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { BatchHttpLink } from 'apollo-link-batch-http'

const url = `${import.meta.env.VITE_BACKEND_URL}api/`

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Widget':
        return `Widget:${object.id}/${object.cardUuid}`
      default:
        return defaultDataIdFromObject(object)
    }
  }
})

const customFetch = (uri, options) => {
  let opName
  const body = JSON.parse(options.body)
  if (Array.isArray(body)) {
    opName = body.map(({ operationName }) => operationName).join(',')
  } else {
    opName = body.operationName
  }
  // to distinguish graphql requests in network tab
  return fetch(`${uri}?opname=${opName}`, options)
}

const createClient = () => {
  const link = new HttpLink({
    uri: `${url}graphql`,
    credentials: 'include',
    fetch: customFetch
  })

  return new ApolloClient({
    link,
    cache
  })
}

const createBatchClient = () => {
  const batchLink = new BatchHttpLink({
    uri: `${url}graphql`,
    credentials: 'include',
    fetch: customFetch
  })

  return new ApolloClient({
    link: batchLink,
    cache
  })
}

const client = createClient()
export const batchClient = createBatchClient()

export default client
