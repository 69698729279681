import React, { type ReactComponentElement, type ReactNode, Component } from 'react'
import ReactQuill, { Quill } from '@praxie/react-quill'
import { Button } from '@praxie/shared'
import { showToastMessage } from 'actions/boardActions'
import IconButton from 'components/buttons/IconButton'
import RoundIconButton from 'components/buttons/RoundIconButton'
import TextButton from 'components/buttons/TextButton'
import ToggleButtonGroup from 'components/buttons/ToggleButtonGroup'
import ActionButtonBlockTool from 'components/common/ActionButtonBlockTool'
import CheckBoxField from 'components/common/CheckBoxField'
import { ColorPicker } from 'components/common/colorPicker/colorPicker'
import DebouncedSearchInput from 'components/common/DebouncedSearchInput'
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/common/dialog/dialog'
import EmptyState from 'components/common/EmptyState'
import EndlessScrollTrigger from 'components/common/EndlessScrollTrigger'
import { FloatingRibbon } from 'components/common/floatingRibbon/ui/floatingRibbon'
import { Menu, MenuItem } from 'components/common/menu/menu'
import { ProgressBar } from 'components/common/progressBar/progressBar'
import RadioField from 'components/common/RadioField'
import { SearchableSelect } from 'components/common/select/searchableSelect/searchableSelect'
import { Select } from 'components/common/select/select'
import SortingIcon from 'components/common/SortingControls/SortingIcon'
import StyleTool from 'components/common/StyleTool'
import { Switcher } from 'components/common/switcher/switcher'
import Tabs from 'components/common/Tabs'
import { SearchInput } from 'components/common/textField/searchInput/searchInput'
import { TextField } from 'components/common/textField/textField'
import ToggleTabs from 'components/common/ToggleTabs'
import { Tooltip } from 'components/common/tooltip/tooltip'
import UserLogo from 'components/common/UserLogo'
import UserRepresentation from 'components/common/UserRepresentation'
import TripleLogo from 'components/common/UsersCell/TripleLogo'
import UsersCell from 'components/common/UsersCell/UsersCell'
import ProvidersListTooltip from 'components/dataLinking/ProvidersListTooltip'
import EditableDropdownMenu from 'components/fields/EditableDropdownMenu'
import MultiCreatable from 'components/fields/MultiCreatable'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import MultiSelect from 'components/fields/MultiSelect'
import ReactSelectWrapper from 'components/fields/ReactSelect'
import AddFileDropdownContainer from 'components/FileWidgetSource/AddFileDropdown'
import FilterRulesPreview from 'components/filters/filterPreview/FilterRulesPreview'
import { ConfirmationModal } from 'components/modals/confirmation/confirmationModal'
import RibbonButton from 'components/ribbonTools/RibbonButton'
import RibbonCheckBox from 'components/ribbonTools/RibbonCheckBox'
import RibbonClearableButton from 'components/ribbonTools/RibbonClearableButton'
import RibbonEditableDropdownMenu from 'components/ribbonTools/RibbonEditableDropdownMenu'
import RibbonGroup from 'components/ribbonTools/RibbonGroup'
import RibbonIconButton from 'components/ribbonTools/RibbonIconButton'
import RibbonPlainInput from 'components/ribbonTools/RibbonPlainInput'
import RibbonPlainSelect from 'components/ribbonTools/RibbonPlainSelect'
import RibbonSplitToggleButton from 'components/ribbonTools/RibbonSplitToggleButton'
import RibbonToggleButton from 'components/ribbonTools/RibbonToggleButton'
import DotsSpinner from 'components/spinners/DotsSpinner'
import LinePlaceholder from 'components/spinners/LinePlaceholder'
import UsersList from 'components/usersList/UsersList'
import { WidgetFullScreenModal } from 'components/widgets/fullScreenModal/widgetFullScreenModal'
import DragDropContextWrapper from 'containers/common/DragDropContextWrapper'
import FilterPreviewDropdownContainer from 'containers/filters/filterPreview/FilterPreviewDropdownContainer'
import FilterSelectorContainer from 'containers/filters/filterSelector/FilterSelectorContainer'
import withBoardMembers from 'containers/hoc/withBoardMembers'
import UsersSelectorContainer from 'containers/usersList/UsersSelectorContainer'
import NumberFieldContainer from 'containers/workflowBuilder/common/NumberFieldContainer'
import { DropdownContentSetup } from 'features/dictionaries/dropdownContentSetup/dropdownContentSetup'
import { WIDGETS_IN_SYSTEM } from 'features/widgets/widgets.constants'
import { importWidgetBundle, importWidgetFromSystem } from 'features/widgets/widgets.helpers'
import ReactSelect from 'react-select'

export type TLoadedWidget = {
  module: {
    createComponent: (...args: unknown[]) => ReactComponentElement<never>
    getWidgetData?: unknown
    adapter?: unknown
    reverseAdapter?: unknown
  }
  Component: ReactComponentElement<never>
}

type TWidgetExtraProps = {
  FileDownloadButton: (...args: unknown[]) => ReactNode
}

// Temporary service until we migrate widgets to the system.
class _WidgetsRequireService {
  private _loadedWidgets = new Map<string, TLoadedWidget>()

  loadWidget = async (widgetName: string, widgetProps: TWidgetExtraProps) => {
    if (WIDGETS_IN_SYSTEM[widgetName]) {
      const widgetInfo = WIDGETS_IN_SYSTEM[widgetName]

      const widget = await importWidgetFromSystem(widgetInfo)

      return {
        module: null,
        Component: widget.default
      }
    }

    return this.getWidget(widgetName, widgetProps)
  }

  getWidget = async (widgetName: string, { FileDownloadButton }: TWidgetExtraProps) => {
    if (this._loadedWidgets.has(widgetName)) return this._loadedWidgets.get(widgetName)

    const widgetModule = await importWidgetBundle(widgetName)

    if (!widgetModule) {
      // @ts-expect-error
      window.getStore().dispatch(showToastMessage({ text: 'Some widgets on board not found' }))
      throw new Error('Widget not found')
    }

    const _Component = widgetModule.createComponent(
      React,
      Component,
      () => null,
      () => null,
      ReactQuill,
      Quill,
      () => null,
      () => null,
      {
        ActionButtonBlockTool,
        AddFileDropdownContainer,
        CheckBoxField,
        ColorPicker,
        ConfirmationModal,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        DebouncedSearchInput,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        DotsSpinner,
        DragDropContextWrapper,
        DropdownContentSetup,
        EditableDropdownMenu,
        EmptyState,
        EndlessScrollTrigger,
        FileDownloadButton,
        FilterPreviewDropdownContainer,
        FilterRulesPreview,
        FilterSelectorContainer,
        FloatingRibbon,
        IconButton,
        LinePlaceholder,
        Menu,
        MenuItem,
        MultiCreatable,
        MultiCreatableSelect,
        MultiSelect,
        NumberFieldContainer,
        ProgressBar,
        ProvidersListTooltip,
        RadioField,
        ReactSelect,
        ReactSelectWrapper,
        RibbonButton,
        RibbonCheckBox,
        RibbonClearableButton,
        RibbonEditableDropdownMenu,
        RibbonGroup,
        RibbonIconButton,
        RibbonPlainInput,
        RibbonPlainSelect,
        RibbonSplitToggleButton,
        RibbonToggleButton,
        RoundIconButton,
        SearchInput,
        SearchableSelect,
        Select,
        SortingIcon,
        StyleTool,
        Switcher,
        Tabs,
        TextButton,
        TextField,
        ToggleButtonGroup,
        ToggleTabs,
        Tooltip,
        TripleLogo,
        UpButton: Button,
        UserLogo,
        UserRepresentation,
        UsersCell,
        UsersList,
        UsersSelectorContainer: withBoardMembers(UsersSelectorContainer),
        WidgetFullScreenModal
      }
    )

    this._loadedWidgets.set(widgetName, { module: widgetModule, Component: _Component })

    return { module: widgetModule, Component: _Component }
  }
}

export const WidgetsRequireService = new _WidgetsRequireService()
