import { lazy, Suspense } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import { setAutoFreeze } from 'immer'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Redirect, Router, Switch } from 'react-router-dom'
import { type Store } from 'redux'
import { REACT_ROOT_DOM_ID } from 'constants/common'
import {
  AUTH_PATHS_LIST,
  EMBEDDED_PAGES_PATHS_LIST,
  INDEX_PATH,
  MAIN_PATHS_LIST,
  PATHS,
  PAYMENT_PATHS_LIST,
  VERIFY_PATHS_LIST
} from 'constants/paths.constants'
import configureStore from 'store/configureStore'
import { initIndexedBD } from 'store/indexDBSetup'
import { history } from 'services/common/history.service'
import { ShimsService } from 'services/common/shims.service'
import { initSentry } from 'helpers/sentry.helpers'
import { AppContainer } from 'containers/common/appContainer/appContainer'
import { ErrorBoundary } from 'components/errorBoundary/errorBoundary'
import '@praxie/upiconsfont/style.css'

// Disable immer auto freeze.
setAutoFreeze(false)

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ga: any
    google: typeof google
    istioHost: string
    widgetsSource: Record<string, () => unknown>
    trialBannerHeight?: number
    getStore: () => Store
  }
}

ShimsService.shimRequestIdleCallback()

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { Route, reduxEnhancer } = initSentry()

const store = configureStore([reduxEnhancer])

initIndexedBD()

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(localizedFormat)

history.listen(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  window.ga('set', 'location', window.location.origin + window.location.pathname)
})

const AUTH_ROUTER_PATHS_LIST = AUTH_PATHS_LIST.map(path => path.routerPath)
const MAIN_ROUTER_PATHS_LIST = MAIN_PATHS_LIST.map(path => path.routerPath)
const EMBEDDED_PAGES_ROUTER_PATHS_LIST = EMBEDDED_PAGES_PATHS_LIST.map(path => path.routerPath)
const VERIFY_ROUTER_PATHS_LIST = VERIFY_PATHS_LIST.map(path => path.routerPath)
const PAYMENT_ROUTER_PATHS_LIST = PAYMENT_PATHS_LIST.map(path => path.routerPath)

const SignInUpContainer = lazy(() => import('containers/signInUp/SignInUpContainer'))
const EmbeddedPages = lazy(() => import('components/embeddedPages/embeddedPages'))
const MainContainer = lazy(() => import('containers/common/MainContainer'))
const BoardAdministrationContainer = lazy(
  () => import('containers/boardAdministration/BoardAdministrationContainer')
)
const Sso = lazy(() => import('components/sso/sso'))
const Verify = lazy(() => import('components/verify/verify'))
const Payment = lazy(() => import('components/payment/payment'))
const WorkspacesAdministrationContainer = lazy(
  () => import('containers/workspacesAdministration/WorkspacesAdministrationContainer')
)
const SnapshotCardContainer = lazy(() => import('features/snapshots/CardSnapshot'))

const container = document.body.querySelector(`#${REACT_ROOT_DOM_ID}`)

if (!container) {
  throw new Error(`Root container with id ${REACT_ROOT_DOM_ID} not found`)
}

const root = createRoot(container)

root.render(
  <ErrorBoundary errorText="An unexpected error has occurred. We're on it!" isRoot>
    <Provider store={store}>
      <Router history={history}>
        <Suspense fallback={null}>
          <AppContainer>
            <Switch>
              <Redirect from={PATHS.root.routerPath} to={PATHS.homepage.routerPath} exact />
              <Route path={AUTH_ROUTER_PATHS_LIST} component={SignInUpContainer} />
              <Route path={EMBEDDED_PAGES_ROUTER_PATHS_LIST} component={EmbeddedPages} exact />
              <Route path={MAIN_ROUTER_PATHS_LIST} component={MainContainer} />
              <Route
                path={PATHS.boardAdministration.routerPath}
                component={BoardAdministrationContainer}
              />
              <Route path={PATHS.sso.routerPath} component={Sso} />
              <Route path={PATHS.admin.routerPath} component={WorkspacesAdministrationContainer} />
              <Route path={VERIFY_ROUTER_PATHS_LIST} component={Verify} />
              <Route path={PAYMENT_ROUTER_PATHS_LIST} component={Payment} />
              <Route path={PATHS.snapshot.routerPath} component={SnapshotCardContainer} />
              {window.location.pathname !== INDEX_PATH && (
                <Redirect to={PATHS.homepage.routerPath} />
              )}
            </Switch>
          </AppContainer>
        </Suspense>
      </Router>
    </Provider>
  </ErrorBoundary>
)
