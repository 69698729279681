import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ArrowIcon from 'assets/images/icons/ic_arrow_bottom_394549.svg?react'
import { Menu } from 'components/common/menu/menu'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/ribbonTools/ribbon-toggle-button.scss'

const VERTICAL_DROPDOWN_OFFSET = -5

export const Toggle = ({
  as: Wrapper,
  id,
  isOpen,
  active,
  disabled,
  className,
  tooltipText,
  tooltipPlacement,
  iconRenderer,
  onToggle
}) => (
  <Tooltip id={`${id}-tooltip`} title={tooltipText} placement={tooltipPlacement}>
    <Wrapper
      id={id}
      className={classNames('ribbon-toggle-button-toggle', className, {
        active,
        disabled,
        open: isOpen
      })}
      onClick={disabled ? null : onToggle}
    >
      <div className="icon-wrapper">{iconRenderer()}</div>
      <div className="state-box">
        <ArrowIcon className="expand-icon" />
      </div>
    </Wrapper>
  </Tooltip>
)

const RibbonToggleButton = ({
  id,
  children,
  iconRenderer,
  as,
  className,
  menuClassName,
  appearance,
  disabled,
  active,
  dropUp,
  pullRight,
  tooltipText,
  menuContainer,
  tooltipPlacement,
  onClick
}) => {
  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = event => {
    setIsOpen(prev => !prev)
    onClick(event)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  return (
    <div
      ref={anchorElRef}
      id={`${id}-dropdown`}
      className={classNames('ribbon-toggle-button', className)}
    >
      <Toggle
        id={id}
        as={as}
        isOpen={isOpen}
        disabled={disabled}
        active={active}
        tooltipText={tooltipText}
        tooltipPlacement={tooltipPlacement}
        iconRenderer={iconRenderer}
        onToggle={handleToggle}
      />
      <Menu
        className={classNames('ribbon-toggle-button-menu', appearance, menuClassName)}
        open={isOpen}
        anchorEl={anchorElRef.current}
        container={menuContainer !== undefined ? menuContainer : anchorElRef.current}
        anchorOrigin={{
          vertical: dropUp ? VERTICAL_DROPDOWN_OFFSET : 'bottom',
          horizontal: pullRight ? 'right' : 'left'
        }}
        sx={{ zIndex: 1050 }}
        transformOrigin={{
          vertical: dropUp ? 'bottom' : VERTICAL_DROPDOWN_OFFSET,
          horizontal: pullRight ? 'right' : 'left'
        }}
        // To prevent MUI popover overlap Bootstrap dialog
        disableEnforceFocus
        onClose={closeDropdown}
        onKeyDown={e => e.stopPropagation()}
      >
        {children}
      </Menu>
    </div>
  )
}

RibbonToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  iconRenderer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  dropUp: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  pullRight: PropTypes.bool,
  tooltipText: PropTypes.string,
  appearance: PropTypes.oneOf(['ordinary', 'horizontal-tools']),
  tooltipPlacement: PropTypes.oneOf(['bottom', 'top', 'right', 'left'])
}

RibbonToggleButton.defaultProps = {
  as: 'button',
  className: '',
  appearance: 'ordinary',
  onClick: () => null,
  dropUp: false,
  disabled: false,
  pullRight: false,
  active: false,
  tooltipText: '',
  tooltipPlacement: 'bottom'
}

export default RibbonToggleButton
