import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { BLOCK_MENU_GROUPS, OBJECT_TYPES, OUTPUT_NAMES } from 'constants/workflows'
import { type TRestApiBlockError } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.types'
import { EWFScheduledRunsFrequency } from 'components/workflowBuilder/triggersBlock/scheduleParams/scheduleParams.types'

export type TWorkflowBlockError = Record<string, string | null | undefined | TRestApiBlockError[]>

export type TWorkflowBlockWarning = {
  [key in EFieldKeys]: string
}

export type TWorkflowBlockInput = Record<string, string | null>

export interface IInputDefinitionRecord {
  inputName: EFieldKeys
  type?: OBJECT_TYPES
  canUpdateHistory?: boolean
  presetValue?: boolean
  isStatic?: boolean
}

export type TWorkflowInputDefinition = {
  [key in EFieldKeys]: IInputDefinitionRecord
}

export interface IOutputDefinitionRecord {
  type: OBJECT_TYPES
  outputName: OUTPUT_NAMES
  defaultPresentationName?: string
}

export type TWorkflowOutput = IOutputDefinitionRecord[]

export type TWorkflowScheduleSettings = {
  frequency: EWFScheduledRunsFrequency
  hours: Array<{ hour: string; minute: string }>
  daysOfWeek?: number[]
  daysOfMonth?: number[]
  monthsOfYear?: number[]
}

export interface IWorkflowBlock<TMeta = Partial<Record<EFieldKeys, unknown>>> {
  error: TWorkflowBlockError
  group: BLOCK_MENU_GROUPS | null
  id: string | null
  index: number
  input: TWorkflowBlockInput
  inputDefinition: TWorkflowInputDefinition
  isCreateOutput: boolean
  meta: TMeta
  name: string
  open: boolean
  output: TWorkflowOutput
  type: EWorkflowBlockTypes
  warn: TWorkflowBlockWarning
  scheduleSettings?: TWorkflowScheduleSettings
}

export interface IWorkflowHistory {
  block: string
  index: number
}

export interface IWorkflowDropdownValue {
  blockId: string
  id: string | null
  history: IWorkflowHistory[]
  isDefined: boolean
  name: string
  outputName: string
  type: OBJECT_TYPES
}

export interface IWorkflowMetadataOutput {
  id: string
  blockId: string
  type: string
  outputName: string
  defaultPresentationName: string
}

export interface IWorkflowMetadata {
  output: IWorkflowMetadataOutput[]
}

export interface IWorkflowListItem {
  id: string
  tenantId: string
  boardId: string
  flowId: string
  name: string
  triggerId: string
  authorId: string
  eventObject: string
  eventType: string
  metadata: IWorkflowMetadata
  isEnabled: boolean
  isExecutionShown: boolean
}

export enum EWorkflowTriggerEventType {
  CLICK = 'click',
  UPDATE = 'update',
  DELETE = 'delete',
  SCHEDULE = 'schedule'
}

export type TCardWorkflowConnection = {
  id: string
  boardId: string
  cardUuid: string
  workflowName: string
  position?: number
}

export enum EWorkflowScenarioReturnFormat {
  TEXT = 0,
  JSON = 1,
  FILE = 2
}

export interface IWorkflowScenarioTemplate {
  id: number
  fields: string[]
  attachments?: boolean
  returns?: EWorkflowScenarioReturnFormat[]
  title: string
  label: string
  value: string
}
